import React from 'react';
import { useEffect } from 'react';

import './styles.scss';

import logo_instragram from '../../assets/images/icons/instagram_logo.svg';
import logo_linkedin from '../../assets/images/icons/linkedin_logo.svg';
// import logo_youtube from '../../assets/images/icons/instagram_logo.svg';

function Footer() {

  useEffect(() => {
    let lgpdLocalStorage = localStorage.getItem('lgpd');
    if(lgpdLocalStorage === null || lgpdLocalStorage === undefined || lgpdLocalStorage === 'false') {
      document.querySelector('.js--lgpd').classList.add('is--cookie');
    };
  },[]);

  function acceptLGPD() {
    localStorage.setItem('lgpd', true);
    if(document.querySelector('.js--lgpd').classList.contains('is--cookie')) {
        document.querySelector('.js--lgpd').classList.remove('is--cookie');
    };
  }

  function refuseLGPD() {
    localStorage.setItem('lgpd', false);
      if(document.querySelector('.js--lgpd').classList.contains('is--cookie')) {
          document.querySelector('.js--lgpd').classList.remove('is--cookie');
      };
  }

  return (
    <>
    <div className="t-footer">
      <div className="t-footer__content">
        <p>
          2023 Copyright © - The Alfred. Todos os direitos reservados. 
        </p>
        <div className="t-footer__content-social">
          <a href="https://www.instagram.com/thealfred.digital/" target="_blank" rel="noopener noreferrer">
            <img src={logo_instragram} alt="Ícone Instagram" />
          </a>
          <a href="https://www.linkedin.com/company/the-alfred/" target="_blank" rel="noopener noreferrer">
            <img src={logo_linkedin} alt="Ícone Linkedin" />
          </a>
          {/* <img src={logo_youtube} alt="Ícone Youtube" /> */}
        </div>
      </div>
    </div>
    <div className="t-cookie-lgpd js--lgpd">
      <div className="t-cookie-lgpd-wrapper">
          <div className="t-cookie-lgpd-infos">
              <p className="t-cookie-lgpd-infos--title">Uso de Cookies</p>
              <p className="t-cookie-lgpd-infos--text">Ei, o site aqui usa cookies pra deixar sua navegação mais legal. Se você continuar usando o site, tá tudo bem com a gente, só clicar em  <strong>Aceito</strong>. Mas se você não estiver confortável, só clicar em <strong>Recusar</strong>.</p>
              <div className="t-cookie-lgpd-infos--buttons">
                <button className="t-cookie-lgpd-accept js--lgpd-accept" onClick={acceptLGPD}>Aceito</button>
                <button className="t-cookie-lgpd-close js--lgpd-close" onClick={refuseLGPD}>Recusar</button>
              </div>
          </div>
      </div>
    </div>
    </>
  );
}

export default Footer;