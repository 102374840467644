/* eslint-disable react/jsx-no-comment-textnodes */
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Typed from 'react-typed';
import { ScrollTop } from '../../hooks/ScrollTop';

import './styles.scss';

import worm from '../../assets/images/worm.svg';
import code_image from '../../assets/images/code_image.jpg';
import arrow_purple from '../../assets/images/icons/arrow_purple.svg';

function Solutions() { 
  const navigate = useNavigate();

  function whatsApp(message){
    let link = `https://api.whatsapp.com/send?phone=5511992022301&text=Oi equipe The Alfred, tudo bem?%0a%0aEstava navegando no site buscando um parceiro e queria conversar sobre:%0a*${message}*.%0aQuando tiverem um tempinho, gostaria de entender como funciona.%0a%0aObrigado(a).`;
    window.open(link, "__blank");
  }

  useEffect(() => {
    ScrollTop();
    let sections = document.querySelectorAll('[data-anime="scroll"]');
    let windowMetade = window.innerHeight * 0.75;

    function animaScroll() {
      sections.forEach((section) => {
        let sectionTop = section.getBoundingClientRect().top;
        let isSectionVisible = (sectionTop - windowMetade) < 0;
        if (isSectionVisible) {
          section.classList.add('ativo')
        }else if(section.classList.contains('ativo')) {
          section.classList.remove('ativo');
        }
      })
    }
  
    if(sections.length) {
      animaScroll();
      window.addEventListener('scroll', animaScroll);
    }
  },[])
  
  return (
    <main className="t-solutions">
      <section className="t-solutions__title">
        <h2>
          Nós tornamos<br/> 
          eficaz toda navegação
          <br/>
          <span className="pink">/*</span>
          <span className="green">{
            <Typed
              strings={[
                "web",
                "mobile"
              ]}
              typeSpeed={100}
              backSpeed={70}
              loop
            />}
          </span>
          <span className="pink">*/</span>
        </h2>
        <img src={worm} alt="Imagem de uma pessoa de fone" />
      </section>

      <section className="t-solutions__quantity" data-anime="scroll">
        { isMobile ?  
        <>  
          <div className="t-solutions__quantity-desc">
            <img src={code_image} alt="Imagem de HTML" />
            <div className="t-solutions__quantity-descItem">
              <div className="t-solutions__quantity-descItem--text">
                <span>:</span>
                <h3 className="counter1">30</h3>
              </div>
              
              <div className="t-solutions__quantity-descItem--text">
                <span>:</span>
                <h3 className="counter2">812M</h3>
              </div>
            </div>
          </div>
          <p>
            Uma agência full-stack que se concentra 
            no uso das tecnologias mais recentes, 
            mas sempre podemos trabalhar com sua 
            stack atual também!
          </p>
        </>
        : 
        <>
          <img src={code_image} alt="Imagem de HTML" />
          <div className="t-solutions__quantity-desc">
            <div className="t-solutions__quantity-descItem">
              <div className="t-solutions__quantity-descItem--text">
                <span>:</span>
                <h3 className="counter1">30</h3>
              </div>
                
              <div className="t-solutions__quantity-descItem--text">
                <span>:</span>
                <h3 className="counter2">812M</h3>
              </div>
              </div>
              <p>
                Uma agência full-stack que se concentra 
                no uso das tecnologias mais recentes, 
                mas sempre podemos trabalhar com sua 
                stack atual também!
              </p>
          </div>
        </>
      }
      </section>

      <section className="t-solutions__whatWeDo" data-anime="scroll">
        <div className="t-solutions__whatWeDo-title">
          <h2>O que <br/><span className="green">fazemos</span></h2>
          <p>Fazemos perguntas significativas, <br/> pensamos, organizamos e agimos.</p>
        </div>
        <div className="t-solutions__whatWeDo-table">
            <div className="t-solutions__whatWeDo-tableColumn">
              <button onClick={ev => {whatsApp(ev.target.value)}} value="Desenvolvimento web">Desenvolvimento web</button>
              <button onClick={ev => {whatsApp(ev.target.value)}} value="CMS">CMS</button>
              <button onClick={ev => {whatsApp(ev.target.value)}} value="Prototipação">Prototipação</button>
            </div>

            <div className="t-solutions__whatWeDo-tableColumn">
              <button onClick={ev => {whatsApp(ev.target.value)}} value="Desenvolvimento Mobile">Desenvolvimento mobile</button>
              <button onClick={ev => {whatsApp(ev.target.value)}} value="Landing Page">Landing Page</button>
              <button onClick={ev => {whatsApp(ev.target.value)}} value="UX / UI">UX / UI</button>
            </div>

            <div className="t-solutions__whatWeDo-tableColumn">
              <button onClick={ev => {whatsApp(ev.target.value)}} value="App">App</button>
              <button onClick={ev => {whatsApp(ev.target.value)}} value="Hotsite">Hotsite</button>
              <button onClick={ev => {whatsApp(ev.target.value)}} value="React">React</button>
            </div>
        </div>
      </section>

      <section className="t-solutions__skills" data-anime="scroll">
        <div className="t-solutions__skills-title">
          <h2>Nossas <br/><span className="green">skills</span></h2>
          <p>Usamos apenas a melhor <br/> tecnologia para nossos clientes.</p>
        </div>
        <div className="t-solutions__skills-wrap">
          <div className="t-solutions__skills-wrapItems">
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/html5_icon.svg').default} alt="Ícone HTML5" />
              <span>HTML5</span>
            </a>
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/css3_icon.svg').default} alt="Ícone CSS3" />
              <span>CSS3</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/javascript_icon.svg').default} alt="Ícone Javascript" />
              <span>Javascript</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/jquery_icon.svg').default} alt="Ícone JQuery" />
              <span>JQuery</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/less_icon.svg').default} alt="Ícone Less" />
              <span>Less</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/react_icon.svg').default} alt="Ícone React" />
              <span>React</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/gatsby_icon.svg').default} alt="Ícone Gatsby" />
              <span>Gatsby</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/graphql_icon.svg').default} alt="Ícone GraphQL" />
              <span>GraphQL</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/wordpress_icon.svg').default} alt="Ícone Wordpress" />
              <span>Wordpress</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/figma_icon.svg').default} alt="ícone Figma" />
              <span>Figma</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/xd_icon.svg').default} alt="Ícone Adobe XD" />
              <span>Adobe XD</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/photoshop_icon.svg').default} alt="Ícone Photoshop" />
              <span>Photoshop</span>
            </a> 
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/illustrator_icon.svg').default} alt="Ícone Illustrator" />
              <span>Illustrator</span>
            </a>
            <a className="t-solutions__skills-wrapItems--flex" href="http://" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/images/icons/vtex_icon.svg').default} alt="Ícone VTEX" />
              <span>VTEX</span>
            </a> 
          </div>
        </div>
      </section>

      <section className="t-solutions__buildTogether" data-anime="scroll">
        <h2>Vamos juntos tirar<br/> sua ideia do <span className="green">papel.</span></h2>
        <div className="t-solutions__buildTogether-circle">
          <a href="mailto:alfredo@thealfred.com.br?subject=SITE THE ALFRED">
            <img src={require('../../assets/images/icons/flyMail_icon.svg').default} alt="ícone de Email com asas" />
          </a> 
        </div>
      </section>

      <section onClick={() => navigate('/')} className="t-solutions__home" data-anime="scroll">
        <span className="green">/</span>
        <h2>Nossos projetos</h2>
        <div className="t-solutions__home-arrow">
          <div className="t-solutions__home-arrowLine"></div>
          <img src={arrow_purple} alt="Seta para direita" />
        </div>
      </section>
    </main>
  );
}

export default Solutions;