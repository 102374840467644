/* eslint-disable react/jsx-no-comment-textnodes */
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { ScrollTop } from '../../hooks/ScrollTop';
// import { isMobile } from 'react-device-detect';
import Typed from 'react-typed';

import './styles.scss';

import working from '../../assets/images/working.svg';
import bitmap from '../../assets/images/Bitmap.jpg';

import alfredo from '../../assets/images/devs/jr-principal.jpg';
import alfredoHover from '../../assets/images/devs/jr-hover.jpg';
import lucas from '../../assets/images/devs/lucas-principal.jpg';
import lucasHover from '../../assets/images/devs/lucas-hover.jpg';
import zet from '../../assets/images/devs/zet-principal.jpg';
import zetHover from '../../assets/images/devs/zet-hover.jpg';
import bea from '../../assets/images/devs/bea-principal.jpg';
import beaHover from '../../assets/images/devs/bea-hover.jpg';
import jim from '../../assets/images/devs/jianho-principal.jpg';
import jimHover from '../../assets/images/devs/jianho-hover.jpg';
import sapuca from '../../assets/images/devs/spc-principal.jpg';
import sapucaHover from '../../assets/images/devs/spc-hover.jpg';
import wev from '../../assets/images/devs/wev-principal.jpg';
import wevHover from '../../assets/images/devs/wev-hover.jpg';
import leona from '../../assets/images/devs/leona-principal.jpg';
import leonaHover from '../../assets/images/devs/leona-hover.jpg';

import arrow_purple from '../../assets/images/icons/arrow_purple.svg';

function AboutUs() {
  const navigate = useNavigate();

  useEffect(() => {
    ScrollTop();
    let sections = document.querySelectorAll('[data-anime="scroll"]');
    let windowMetade = window.innerHeight * 0.85;

    function animaScroll() {
      sections.forEach((section) => {
        let sectionTop = section.getBoundingClientRect().top;
        let isSectionVisible = (sectionTop - windowMetade) < 0;
        if (isSectionVisible) {
          section.classList.add('ativo')
        }else if(section.classList.contains('ativo')) {
          section.classList.remove('ativo');
        }
      })
    }
  
    if(sections.length) {
      animaScroll();
      window.addEventListener('scroll', animaScroll);
    }
  },[])

  function onMouseEvent(event, mainImg, hoverImg, hover, blur) {
    if (hover) {
      event.currentTarget.firstChild.src = hoverImg;
      event.currentTarget.lastChild.classList.add('is--active');
    }

    if (blur) {
      event.currentTarget.firstChild.src = mainImg;
      event.currentTarget.lastChild.classList.remove('is--active');
    }
  }

  return (
    <main className="t-aboutUs">
      <section className="t-aboutUs__title">
        <div className="t-aboutUs__title-stick">
          <h2>
            Desenvolvemos <br/>
            <span className="pink">/*</span>
            <span className="green">
              {
                <Typed
                  strings={[
                    "Desenvolvimento Web",
                    "E-commerce VTEX",
                    "Análise CRO",
                    "UX",
                    "SEO",
                    "CRM",
                  ]}
                  typeSpeed={100}
                  backSpeed={10}
                  loop
                  backDelay={2000}
                />
              }
            </span>
            <span className="pink">*/</span>
            <br/>
            para aperfeiçoar<br/> negócios online
          </h2>
        </div>

        <div className="t-aboutUs__desc" data-anime="scroll">
          <img src={working} alt="Imagem de uma pessoa de fone" />
          <p className="t-aboutUs__desc">
            Nossa equipe é experiente, apaixonada e dedicada a 
            criar produtos digitais de acordo com os objetivos e 
            necessidades do seu negócio.
          </p>
          <img src={bitmap} alt="Imagem bitmap" />
        </div>
      </section>

      <section className="t-aboutUs__preTeam" data-anime="scroll">
        <h2>Time de <br/><span className="green">coração</span></h2>
        <div className="t-aboutUs__preTeam-wrap">
          <p>
            The Alfred tem o objetivo de atender toda 
            a demanda de desenvolvimento de programação.
          </p>
          <p>
            Com 10 anos trabalhando em agências de marketing 
            digital, temos todo o conhecimento necessário para 
            apoiar toda a demanda de desenvolvimento de programação 
            que as agências têm mas que não faz parte do seu core business. 
            Assim as equipes das agências conseguem dedicar seu tempo para 
            criar campanhas inovadoras e deixar toda parte de tecnologia com 
            nosso time de especialistas.
          </p>
        </div>
      </section>

      <section className="t-aboutUs__team" data-anime="scroll">
        <div className="t-aboutUs__team-wrap">

          <div className="t-aboutUs__team-wrapItem--one">
            <div className='t-aboutUs__team-wrapItem__card is--active'
              onMouseOver={e => onMouseEvent(e, alfredo, alfredoHover, true, false)}
              onMouseOut={e => onMouseEvent(e, alfredo, alfredoHover, false, true)} 
            >
              <img src={alfredo} alt="Foto Alfredo" />
              <a href="https://www.linkedin.com/in/jrvilacha/" target="_blank" rel="noopener noreferrer">Alfredo</a>
            </div>
            <div className="t-aboutUs__team-wrapItem__card is--active"
              onMouseOver={e => onMouseEvent(e, zet, zetHover, true, false)}
              onMouseOut={e => onMouseEvent(e, zet, zetHover, false, true)} 
            >
              <img src={zet} alt="Foto Leonardo Tavares" />
              <a href="https://www.linkedin.com/in/leonardofts/" target="_blank" rel="noopener noreferrer">Leonardo Tavares</a>
            </div>
            <div className="t-aboutUs__team-wrapItem__card is--active"
              onMouseOver={e => onMouseEvent(e, sapuca, sapucaHover, true, false)}
              onMouseOut={e => onMouseEvent(e, sapuca, sapucaHover, false, true)} 
            >
              <img src={sapuca} alt="Foto Lucas Sapucaia" 
              />
              <a href="https://www.linkedin.com/in/lucas-sapucaia-de-lima-3837041a0/" target="_blank" rel="noopener noreferrer">Lucas Sapucaia</a>
            </div>
            <div className="t-aboutUs__team-wrapItem__card is--active"
              onMouseOver={e => onMouseEvent(e, wev, wevHover, true, false)}
              onMouseOut={e => onMouseEvent(e, wev, wevHover, false, true)} 
            >
              <img src={wev} alt="Foto Weverton Costa" 
              />
              <a href="https://www.linkedin.com/in/weverton-s-s-costa/" target="_blank" rel="noopener noreferrer">Weverton Costa</a>
            </div>
          </div>

          <div className="t-aboutUs__team-wrapItem--two">
            <div className='t-aboutUs__team-wrapItem__card is--active'
              onMouseOver={e => onMouseEvent(e, lucas, lucasHover, true, false)}
              onMouseOut={e => onMouseEvent(e, lucas, lucasHover, false, true)} 
            >
              <img src={lucas} alt="Foto Lucas Cassimiro" 
              />
              <a onClick={(ev) => console.log(ev.currentTarget)} href="https://www.linkedin.com/in/lucas-cassimiro-413299132" target="_blank" rel="noopener noreferrer">Lucas Cassimiro</a>
            </div>
            <div className="t-aboutUs__team-wrapItem__card is--active"
              onMouseOver={e => onMouseEvent(e, bea, beaHover, true, false)}
              onMouseOut={e => onMouseEvent(e, bea, beaHover, false, true)} 
            >
              <img src={bea} alt="Foto beatriz Azevedo" 
              />
              <a href="https://www.linkedin.com/in/beatriz-azevedo-915615149" target="_blank" rel="noopener noreferrer">Beatriz Azevedo</a>
            </div>
            <div className="t-aboutUs__team-wrapItem__card is--active"
              onMouseOver={e => onMouseEvent(e, jim, jimHover, true, false)}
              onMouseOut={e => onMouseEvent(e, jim, jimHover, false, true)} 
            >
              <img src={jim} alt="Foto Victor Borborema" 
              />
              <a href="https://www.linkedin.com/in/victor-borborema-araujo-8345b9127/" target="_blank" rel="noopener noreferrer">Victor Borborema</a>
            </div>
            <div className="t-aboutUs__team-wrapItem__card is--active"
              onMouseOver={e => onMouseEvent(e, leona, leonaHover, true, false)}
              onMouseOut={e => onMouseEvent(e, leona, leonaHover, false, true)} 
            >
              <img src={leona} alt="Foto Leona Evangelista" 
              />
              <a href="https://www.linkedin.com/in/leona-evangelista/ " target="_blank" rel="noreferrer">Leona Evangelista</a>
            </div>
          </div>
        </div>
      </section>

      <section onClick={() => navigate('/solucoes')} className="t-aboutUs__solutions" data-anime="scroll">
        <span className="green">/</span>
        <h2>Nossas soluções</h2>
        <div className="t-aboutUs__solutions-arrow">
          <div className="t-aboutUs__solutions-arrowLine"></div>
          <img src={arrow_purple} alt="Seta para direita" />
        </div>
      </section>
    </main>
  );
}

export default AboutUs;