import React from 'react';
import { useEffect } from 'react';
import './styles.scss';

import logo from "../../assets/images/logo/logo-light.svg";
import logo_insta from "../../assets/images/icons/instagram_logo.svg";
import logo_in from "../../assets/images/icons/linkedin_logo.svg";
import logo_wpp from "../../assets/images/icons/whatsapp_logo.svg";
import logo_mail from "../../assets/images/icons/googleplus_logo.svg";

function Building() {

  useEffect(() =>{
    document.querySelector('.t-navBar').style.display ="none";
  },[]);

  return (
    <div className="t-building">
      <img src={logo} alt="Logo TheAlfred" />
      <div className="t-building__title">
        <h2>Site em <br/>
          <span className="green"> construção</span>
        </h2>
        <div className="t-building__title-pink--boxGradient"/>
      </div>
      <div className="t-building__contact">
        <p>Fale <span className="pink">conosco:</span></p>
        <div className="t-building__contact-wrap">
          <div className="t-building__contact-wrapItem">
            <a href="https://api.whatsapp.com/send?phone=5511992022301" target="_blank" rel="noopener noreferrer"><img src={logo_wpp} alt="Logo WhatsApp" /><span>(11) 99202-2301</span></a>
            <a href="mailto:alfredo@thealfred.com.br"><img src={logo_mail} alt="Logo email" /><span>alfredo@thealfred.com.br</span></a>
          </div>
          <div className="t-building__contact-wrapItem">
            <a href="https://www.instagram.com/thealfred.digital/" target="_blank" rel="noopener noreferrer"><img src={logo_insta} alt="Logo instagram" /><span>@thealfred.digital</span></a>
            <a href="https://www.linkedin.com/company/the-alfred/" target="_blank" rel="noopener noreferrer"><img src={logo_in} alt="Logo Linkedin" /><span>The Alfred</span></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Building;