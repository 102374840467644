/* eslint-disable react/jsx-no-comment-textnodes */
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollTop } from '../../hooks/ScrollTop';

import './styles.scss';

import vtex_image from '../../assets/images/vtex-logo.png';
import team_image from '../../assets/images/vtex-thealfred-team.jpg';

function Vtex() { 
  const navigate = useNavigate();

  function whatsApp(message){
    let link = `https://api.whatsapp.com/send?phone=5511992022301&text=Oi equipe The Alfred, tudo bem?%0a%0aEstava navegando no site buscando um parceiro e queria conversar sobre:%0a*${message}*.%0aQuando tiverem um tempinho, gostaria de entender como funciona.%0a%0aObrigado(a).`;
    window.open(link, "__blank");
  }

  useEffect(() => {
    ScrollTop();
    let sections = document.querySelectorAll('[data-anime="scroll"]');
    let windowMetade = window.innerHeight * 0.75;

    function animaScroll() {
      sections.forEach((section) => {
        let sectionTop = section.getBoundingClientRect().top;
        let isSectionVisible = (sectionTop - windowMetade) < 0;
        if (isSectionVisible) {
          section.classList.add('ativo')
        }else if(section.classList.contains('ativo')) {
          section.classList.remove('ativo');
        }
      })
    }
  
    if(sections.length) {
      animaScroll();
      window.addEventListener('scroll', animaScroll);
    }
  },[])
  
  return (
    <main className="t-vtex">
      <section className="t-vtex__top">
        <div className="t-vtex__top-left">
          <h2><span className="pink">/*</span>
            <span className="green">VTEX
            </span>
            <span className="pink">*/</span>
          </h2>
          <h3 className="t-vtex__top-subtitle">
            uma suite de tecnologias para e-commerce, comércio unificado e omnichannel.
          </h3>
          <button onClick={ev => {whatsApp(ev.target.value)}} value="VTEX"><span>Entrar em contato</span></button>
        </div>
        <div className="t-vtex__top-right">
          <img src={vtex_image} alt="VTEX" />
        </div>
      </section>

      <section className="t-vtex__whatIs" data-anime="scroll">
        <div className="t-vtex__whatIs-left">
          <h1 className="t-vtex__title">
            o que é a <span className="green">VTEX
            </span>
          </h1>
        </div>
        <div className="t-vtex__whatIs-right">
          <p className="t-vtex__subtitle">
            VTEX é uma plataforma de e-commerce SaaS (Software as a Service).
          </p>
          <p className="t-vtex__text">
          A VTEX é uma plataforma de comércio eletrônico que permite a criação de lojas virtuais para venda de produtos e serviços. Ela foi criada no Brasil em 1999 e atualmente é utilizada por empresas de diversos segmentos em mais de 38 países.<br></br><br></br>Ela oferece uma série de ferramentas e recursos para facilitar o gerenciamento de uma loja virtual, como integração com meios de pagamento, gerenciamento de estoque e logística, análise de dados e muito mais.
          </p>
        </div>
      </section>

      <section className="t-vtex__pink" data-anime="scroll">
        <div className="t-vtex__pink-title">
          <h4 className="t-vtex__title">Você está em <br/><span className="green">boa companhia</span></h4>
        </div>
        <div className="t-vtex__pink-infos">
          <p>38 países com lojas ativas</p>
          <p>+ 3.200 lojas online e ativas</p>
        </div>
      </section>

      <section className="t-vtex__experience" data-anime="scroll">
        <div className="t-vtex__experience-left" >
          <h4 className="t-vtex__title">+10 anos de<br/><span className="green">experiência</span></h4>
          <p className="t-vtex__subtitle">SOMOS O PRESENTE, PLANEJANDO O FUTURO</p>
          <p className="t-vtex__text"> Ao longo desses 10 anos, pensamos e desenvolvemos tecnologia, somos apaixonados em entregar o máximo aos nossos parceiros.<br></br><br></br>Vamos juntos nessa transformar o mercado digital?</p>
        </div>
        <div className="t-vtex__experience-right">
          <img src={team_image} alt="The Alfred" />
        </div>
       
      </section>

      <section className="t-vtex__differentials" data-anime="scroll">
        <div className="t-vtex__differentials-top">
          <h4 className="t-vtex__title">Diferenciais<br/><span className="green">VTEX</span></h4>
          <p className="t-vtex__subtitle">Vamos bater um papo?</p>
        </div>
        {/* <p className="t-vtex__text">
          Na prática, isso significa que a VTEX é responsável por toda estrutura necessária para que os lojistas possam cadastrar seus produtos, integrar meios de pagamento, calcular frete, etc. Dessa forma, a empresa pode focar apenas com a venda de seus produtos no ambiente online.
        </p> */}
        <div className="t-vtex__differentials-items">
          <article className="t-vtex__differentials-item">
            <p className="t-vtex__differentials-item--title">Smart Checkout</p>
            <p className="t-vtex__differentials-item--text">Quer aumentar sua taxa de conversão com a velocidade em finalizar sua compra? Com o Smart Checkout isso é possível, reduzindo o tempo de finalização de compra para uma média de 1 minuto e meio, com segurança e fluidez.</p>
          </article>
          <article className="t-vtex__differentials-item">
            <p className="t-vtex__differentials-item--title">Escalabilidade e Performance</p>
            <p className="t-vtex__differentials-item--text">Não se preocupe, sua loja não terá problemas de infraestrutura ou caídas por picos de acessos. Para garantir uma navegação fluída, temos diversos ganhos de performance vinda dos próprios servidores da VTEX, garantindo assim que toda a velocidade do site esteja sempre no seu auge.</p>
          </article>
          <article className="t-vtex__differentials-item">
            <p className="t-vtex__differentials-item--title">Integrações</p>
            <p className="t-vtex__differentials-item--text">O ecossistema de integrações da VTEX hoje suporta a maioria dos players do mercado, desde marketing places até integradores de ERP, Meios de Pagamento ou até mesmo aplicativos de performance e usabilidade. </p>
          </article>
          <article className="t-vtex__differentials-item">
            <p className="t-vtex__differentials-item--title">Multilínguas e moedas</p>
            <p className="t-vtex__differentials-item--text">Ta afim de expandir para outros territórios? Não se preocupe, a VTEX te da todo o suporte para outras moedas e línguas do mundo inteiro. Não se limite apenas no seu país de origem.</p>
          </article>
          <article className="t-vtex__differentials-item">
            <p className="t-vtex__differentials-item--title">B2B</p>
            <p className="t-vtex__differentials-item--text">Se seu negócio depende de um cadastro exclusivo, preços únicos para determinados clientes ou até mesmo um fluxo de autoatendimento, com a VTEX tudo isso é possível. Toda a parte B2B do seu negócio poderá sem problemas algum seguir um fluxo intuitivo e com total clareza para seus parceiros e clientes.</p>
          </article>
        </div>
        <button onClick={() => navigate('/solucoes')} value="VTEX"><span>Veja nossos projetos</span></button>
      </section>

    </main>
  );
}

export default Vtex;