import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

// import { isMobile } from 'react-device-detect';

import logo from '../../assets/images/logo/logo-light.svg';

import './styles.scss';

function NavBar() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  function toggleClass() {
    setIsOpen(!isOpen);
  }

  if(isMobile) {

  }
  
  return (
    <div className="t-navBar">
      <img onClick={() => navigate('/')} src={logo} alt="Logo TheAlfred" />
      <div className="t-navBar__wrapper">
        <div className={isOpen ? 't-navBar__wrapper-menu open' : 't-navBar__wrapper-menu'} onClick={toggleClass}>
          <div className="t-navBar__menu-icon"/>
          <div className="t-navBar__menu-icon"/>
          <div className="t-navBar__menu-icon"/>
          <p>Menu</p>
        </div>
          
        <div className="t-navBar__wrapper-formShortcut">
          <p>Mande sua dúvida</p>
          <div className="t-navBar__wrapper-formShortcut-click">
            <p>ctlr + K</p>
          </div>
        </div>
      </div>

      <div style={{transform: isMobile ? 'translateX(500px)' : 'translateX(3000px)'}} className={ isOpen ? 't-navBar__openMenu open' : 't-navBar__openMenu' }>
        <div className="t-navBar__openMenu-items">
          <button onClick={() => {
            toggleClass();
            navigate('/');
            }}>Home</button>
          <button onClick={() => {
            toggleClass();
            navigate('/solucoes')
            }}>Soluções</button>
          <button onClick={() => {
            toggleClass();
            navigate('/sobre-nos')
            }}>Sobre nós</button>
            <button onClick={() => {
            toggleClass();
            navigate('/vtex')
            }}>VTEX</button>
        </div>
      </div>
    </div>
  );
}

export default NavBar;