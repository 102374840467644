import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Building from '../pages/Building';
import Home from '../pages/Home'
import AboutUs from '../pages/AboutUs'
import Error404 from '../pages/Error404';
import Solutions from '../pages/Solutions';
import Vtex from '../pages/Vtex';

import NavBar from '../components/NavBar'
import Footer from '../components/Footer';

const RoutesWeb = () => {

  return(
    <Router>
      <NavBar/>
      <Routes>
        <Route  path="/" element={<Home/>}/>
        <Route  path="/sobre-nos" element={<AboutUs/>}/>
        <Route path="/solucoes" element={<Solutions/>} />
        <Route path="/Building" element={<Building />}/>
        <Route  path="*" element={<Error404/>}/>
        <Route path="/vtex" element={<Vtex/>} />
      </Routes>
      <Footer/>
    </Router>
    
  )
}

export default RoutesWeb;