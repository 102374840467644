import React from "react";
import Routes from "./routes/routes";
import "./global/reset.scss";
import "./global/global.scss";

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
