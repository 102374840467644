import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { ScrollTop } from '../../hooks/ScrollTop';
import './styles.scss';

import touch from '../../assets/images/touch.jpg';
import arrow from '../../assets/images/icons/arrow_down.svg';

import clientNatural from '../../assets/images/Natural-Meat.jpg';
import clientVizcaya from '../../assets/images/vizcaya.jpg';
import clientSolucao from '../../assets/images/solucao-cabos.jpg';
import clientFrancesca from '../../assets/images/shop-francesca.jpg';
import clientJustclick from '../../assets/images/just-click.jpg';
import clientAlexandre from '../../assets/images/alexandre-folheados.jpg';
import clientReplay from '../../assets/images/replay-jeans.jpg';


function Home() {
  const navigate = useNavigate();

  //UseEffect for fadeIn/Out Animation
  useEffect(() => {
    console.log('Bom, se você chegou aqui, provavelmente você saiba o que está fazendo. (Se você não sabe, recomendo que meta o pé), nosso time ficará feliz em saber que você gostaria de se juntar a nós. Estamos no faro de pessoas que sabem o que fazem. Fala com a gente!!')
    ScrollTop();
    let sections = document.querySelectorAll('[data-anime="scroll"]');
    let windowMetade = window.innerHeight * 0.6;

    function animaScroll() {
      sections.forEach((section) => {
        let sectionTop = section.getBoundingClientRect().top;
        let isSectionVisible = (sectionTop - windowMetade) < 0;
        if (isSectionVisible) {
          section.classList.add('ativo')
        }else if(section.classList.contains('ativo')) {
          section.classList.remove('ativo');
        }
      })
    }
  
    if(sections.length) {
      animaScroll();
      window.addEventListener('scroll', animaScroll);
    }

    if(isMobile) {
      document.querySelectorAll('.t-home__projects-wrapItem__card').forEach((item) =>{
        item.addEventListener('click', (ev) => {
          ev.preventDefault();
          item.classList.toggle('is--active');
        });
      });
    }
  },[]);

  //CTRL + K SHORTCUT FUNCTION

  // document.addEventListener('keydown', (event) => {
  //   if(event.ctrlKey && event.key.toLowerCase() === 'k') {
  //     event.preventDefault();
  //     alert("ctrl + k");
  //   }
  // });
  
  return ( 
    <main className="t-home">
      <section className="t-home__haveFun">
        <div className="t-home__haveFun-title">
          <h2>Experiência digital?
          <br/>
          <span className="green">Vem </span>
            com a gente
          </h2>
          <div className="t-home__haveFun-title-pink--boxGradient"/>
         </div> 
         <img src={arrow} alt="Descrubra" />
      </section>

      <section className="t-home__weAre" data-anime="scroll">
        <div className="t-home__weAre-wrapOne">
          <h2>Somos obcecados em nossos <span className="green">clientes</span></h2>
        </div>
        <div className="t-home__weAre-wrapThree">
          <p className="text-absolute">
            Nós temos a responsabilidade de entregar a melhor experiência para 
            o seu negócio com a finalidade de transformá-lo através da Tecnologia.
            <br />
            <span className="pink">Está em nosso sangue.</span>
          </p>
          <img src={touch} alt="Mulher tocando no ar, simulando uma tela digital flutuante" />
          <div className="t-home__weAre-wrapThree-cards">
            <div className="t-home__weAre-wrapThree-cardsItem">
              <p>Somos disruptivos:</p>
              <span>Nosso time tem a responsabilidade de entregar além do esperado</span>
            </div>
            <div className="t-home__weAre-wrapThree-cardsItem">
              <p>Nós somos especialistas:</p>
              <span>Resolvemos o seu problema</span>
            </div>
          </div>
        </div>
      </section>

      <section className="t-home__projects" data-anime="scroll">
        <div className="t-home__projects-wrap">
          <div className="t-home__projects-wrapItem--one">
            <h2>Projetos<br/> <span className="green">recentes</span></h2>


            <div onClick={(ev) => ev.target.classList.toggle('is--active')} className="t-home__projects-wrapItem__card">
              <p>Natural Meat</p>
              <img src={clientNatural} alt="Natural Meat" />
              <a className="link-hover" href="www">E-COMMERCE VTEX IO</a>
            </div>
            <div onClick={(ev) => ev.target.classList.toggle('is--active')} className="t-home__projects-wrapItem__card">
              <p>Shop Francesca</p>
              <img src={clientFrancesca} alt="Shop Francesca" />
              <a className="link-hover" href="www">E-COMMERCE VTEX</a>
            </div>
            <div onClick={(ev) => ev.target.classList.toggle('is--active')} className="t-home__projects-wrapItem__card">
              <p>Solução Cabos</p>
              <img src={clientSolucao} alt="Solucao Cabos" />
              <a className="link-hover" href="www">E-COMMERCE VTEX</a>
            </div>
          </div>

          <div className="t-home__projects-wrapItem--two">
            <div onClick={(ev) => ev.target.classList.toggle('is--active')} className="t-home__projects-wrapItem__card">
              <p>Replay Jeans</p>
              <img src={clientReplay} alt="Replay" />
              <a className="link-hover" href="www">E-COMMERCE VTEX</a>
            </div>
            <div onClick={(ev) => ev.target.classList.toggle('is--active')} className="t-home__projects-wrapItem__card">
              <p>Alexandre Folheados</p>
              <img src={clientAlexandre} alt="Alexandre Folheados" />
              <a className="link-hover" href="www">E-COMMERCE VTEX</a>
            </div>
            <div onClick={(ev) => ev.target.classList.toggle('is--active')} className="t-home__projects-wrapItem__card">
              <p>Vizcaya</p>
              <img src={clientVizcaya} alt="Vizcaya" />
              <a className="link-hover" href="www">E-COMMERCE VTEX</a>
            </div>
            <div onClick={(ev) => ev.target.classList.toggle('is--active')} className="t-home__projects-wrapItem__card">
              <p>Just Click</p>
              <img src={clientJustclick} alt="Just Click" />
              <a className="link-hover" href="www">E-COMMERCE VTEX</a>
            </div>
          </div>


        </div>
        <button style={{display: 'none'}}><span>Todos os cases</span></button>
      </section>

      <section className="t-home__weCode" data-anime="scroll">
        <div className="t-home__weCode-wrap">
          <h2>Projetos<br/> <span className="green">recentes</span></h2>
          <p>Dê uma espiada em nosso processo para ver como transformamos ideias em experiência de navegação!</p>
        </div>
        <div className="t-home__weCode-list">
          <h3>Comece com a ideia</h3>
          <p>Acreditamos que todas as coisas boas começam com grandes ideias</p>
        </div>
        <div className="t-home__weCode-list">
          <h3>Prototipação</h3>
          <p>Avaliamos a premissa de design. Se não tiver, a gente cria =)</p>
        </div>
        <div className="t-home__weCode-list">
          <h3>Desenvolvimento</h3>
          <p>Hora de fazer acontecer. Aqui o protótipo ganha vida.</p>
        </div>
        <div className="t-home__weCode-list">
          <h3>QA e Teste</h3>
          <p>Caçadores de Bugs entram em ação. Não vai subir ninguém!</p>
        </div>
        <div className="t-home__weCode-list">
          <h3>Ongoing</h3>
          <p>A programação é um estilo de vida: não tem fim e fica sempre melhor</p>
        </div>
        <button onClick={() => navigate('/solucoes')}><span>Nossas soluções</span></button>
      </section>
    </main>
  );
}

export default Home;