import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

function Error404() {
  const navigate = useNavigate();
  return (
    <div className="t-404">
      <h1>
        Ops, deu ruim.
        404 essa página não existe por aqui
      </h1>
      <button onClick={() => navigate('/')}> {'>'} Voltar para home {'<'} </button>
    </div>
  );
}

export default Error404;